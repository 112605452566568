:root {
  --brand-color: #1c6176;
  --text-color: #303030
}

body {
  font-family: 'Poppins', sans-serif;
  color: var(--text-color);
  background: #f6f9ff;
  background: -webkit-linear-gradient(to right, #ffffff, #dde1e9);
  background: linear-gradient(to right, #ffffff, #dde1e9);

}

.authWrap .row {
  min-height: 100vh;
}

.display-card {
  background: #fff;
  border-radius: 12px;
  box-shadow: 0 0px 15px rgb(82 63 105 / 15%);
  padding: 1.5rem 1rem;
  position: relative;
}

.authWrap h1 {
  font-size: 22px;
  font-weight: 600;
}

.authWrap p {
  font-size: 14px;
  color: #797979;
}

.siteMenu ul {
  list-style: none;
  padding: 0;
  margin: 0;
  text-align: right;
}

.siteMenu ul li {
  display: inline-block;
  padding-left: 15px;
}

.btn-danger {
  background: #dc3545;
  color: #fff;
  font-size: 14px;
  border: 0;
  padding: 12px 20px !important;
  border-radius: 8px !important;
  outline: none;
  transition: all ease 0.6s;
  display: inline-block;
  border: 1px solid #dc3545;
}

.univ-btn,
.univ-btn-light,
.univ-btn-outline {
  background: var(--brand-color);
  color: #fff;
  font-size: 14px;
  border: 0;
  padding: 12px 30px !important;
  border-radius: 8px;
  outline: none;
  transition: all ease 0.6s;
  display: inline-block;
  border: 1px solid var(--brand-color);
  text-decoration: none;
}

.univ-btn:disabled {
  opacity: 0.6;
}

.univ-btn-outline {
  background: transparent;
  border: 1px solid var(--brand-color);
  color: var(--brand-color);
}

.univ-btn:hover,
.univ-btn-outline:hover {
  transition: all ease 0.6s;
  box-shadow: 0 4px 20px rgb(0 0 0 / 43%);
  background: var(--brand-color);
  color: #fff;
}

.univ-btn-light {
  background: #fff;
  color: var(--brand-color);
}

.brand-color {
  color: var(--brand-color);
}

header {
  padding: 10px 0;
}

.bannerWrap {
  background: url('../images/banner-bg.jpg') no-repeat;
  background-size: cover;
  padding: 3rem;
  min-height: 40vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-shadow: 0 0px 15px rgb(0 0 0 / 5%);
  border-radius: 15px;
}

.bannerWrap h1 {
  font-size: 36px;
  font-weight: 600;
  color: #fff;
}

.bannerWrap button.univ-btn-light {
  width: 300px;
}

.bannerWrap p,
.worksContent p,
.worksContent ul li {
  font-size: 14px;
  color: #fff;
  line-height: 26px;
}

.section-title h2 {
  font-size: 26px;
  margin: 0;
  font-weight: 600;
}

.feature-block {
  padding: 1rem 1.5rem;
  transition: all ease 0.6s;
}

.feature-block:hover {
  background: #fff;
  box-shadow: 0 0px 15px rgb(0 0 0 / 5%);
  border-radius: 10px;
  transition: all ease 0.6s;
}

.feature-block h3 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 1rem;
}

.feature-block p {
  font-size: 14px;
}

.feature-block img {
  max-width: 60px;
  margin: 1rem auto;
}

.worksImage img {
  width: 100%;
}

.worksContent h3 {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 1rem;
}

.worksContent p,
.worksContent ul li {
  color: var(--text-color);
}

.worksContent {
  background: #fff;
  padding: 3rem;
  border-radius: 10px;
  box-shadow: 0 0px 15px rgb(0 0 0 / 5%);
}

.copyrightText p,
.authLogin p {
  font-size: 14px;
}

.modal-header {
  border: 0;
  padding-bottom: 0;
}

.loginModal .modal-content {
  border: 0;
  border-radius: 15px;
  max-width: 350px;
  margin: auto;
}

.modal-body {
  padding: 20px 25px 30px 25px;
}

.authLogin h2,
.docUpload h2 {
  font-size: 24px;
  font-weight: 600;
  color: #333;
  margin-bottom: 1rem;
}

.authLogin .googleLogin {
  width: 100%;
  background: transparent;
  outline: none;
  border: 1px solid #ddd;
  font-size: 14px;
  padding: 6px;
  border-radius: 10px;
}

.authLogin .googleLogin img {
  max-width: 30px;
}

.authLogin label {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 10px;
}

.univ-input {
  width: 100%;
  font-size: 14px;
  border: 1px solid #ddd;
  border-radius: 6px;
  padding: 8px 15px;
  outline: none;
}

.docUpload h2 {
  font-size: 16px;
  margin-bottom: 10px;
}

.uploadWrap {
  border: 1px dashed #ddd;
  border-radius: 10px;
  padding: 15px;
  background: #f9f9f9;
}

.uploadWrap svg {
  font-size: 45px;
  margin-bottom: 10px;
}

.uploadWrap h3 {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 5px;
}

.uploadWrap p {
  font-size: 12px;
  font-style: italic;
}

.file-upload__label {
  display: inline-block;
  padding: 8px 30px;
  color: var(--brand-color);
  border: 1px solid var(--brand-color);
  border-radius: 0.4em;
  transition: background .3s;
  margin-top: 10px;
  cursor: pointer;
  font-size: 14px;
}

.file-upload__input {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  font-size: 1;
  width: 0;
  height: 100%;
  opacity: 0;
}

.templateProgress ul {
  list-style: none;
  padding: 0;
  margin: 0;
  margin-top: 1rem;
}

.templateProgress ul li {
  padding: 15px;
  transition: all ease 0.6s;
}

.templateProgress ul li:hover {
  box-shadow: 0 4px 15px rgb(0 0 0 / 15%);
  transition: all ease 0.6s;
  background: #f4f8ff;
  border-radius: 10px;
}

.templateProgress .fileIcon {
  font-size: 35px;
  margin-right: 10px;
}

.templateProgress .templateName {
  font-size: 14px;
  color: #000;
  font-weight: 500;
  margin-bottom: 5px;
  display: block;
}

.templateProgress .progress {
  height: 10px;
}

.templateDetail {
  width: 80%;
}

.deleteIcon svg {
  font-size: 25px;
  cursor: pointer;
}

@media (max-width: 767px) {
  .bannerWrap button.univ-btn-light {
    width: 150px;
  }
}

.alert {
  font-size: 12px !important;
  --bs-alert-padding-y: 0.5rem;
}

.message.file-type ul li {
  width: 135px;
}

.message.file-type ul li img {
  /* height: 190px; */
  height: -webkit-fill-available !important;
}


.file-list {
  list-style: none;
  padding: 0;
  display: flex;
  flex-wrap: nowrap;
  /* Ensure items don't wrap to next line */
  overflow-x: auto;
  /* Enable horizontal scrolling */
}

.file-type {
  margin-top: -10px;
}

.file-item {
  position: relative;
  overflow: hidden;
}

.fileDetail {
  position: absolute;
  width: 100%;
  top: 0;
  bottom: 0;
  background: rgb(28 97 118 / 70%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
  text-align: center;
  padding: 15px;
  cursor: pointer;
  transition: all ease 0.4s;
  left: -100%;
}

.file-item:hover .fileDetail {
  left: 0;
  transition: all ease 0.4s;
}

.fileDetail svg {
  font-size: 30px;
  margin-bottom: 10px;
}

.addDocIcon {
  flex: 0 0 20%;
  /* 20% width for the "Upload Document" button */
  text-align: center;
}



.previewmodal {
  padding: 15px;
}

.previewmodal.modal-height {
  /* height: 680px; */
  height: -webkit-fill-available !important;
}

.EditModal.modal-height{
  height: -webkit-fill-available;
}


.previewmodal img {
  width: 100%;
}

.PreviewModal .modal-header h3 {
  font-size: 16px;
  margin-bottom: 0;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.spinner-container svg {
  animation: spin 2s infinite linear;
}

.blue-edit {
  color: #135a70 !important;
  size: 50px;
}

.custom-Edit-modal .modal-dialog {
  width: 600px !important; /* Set the desired width */
}



.previewmodal-button .dropdown button,
.previewmodal-button .dropdown-menu {
  font-size: 14px;
  padding: 10px 14px;
}

.color-primary {
  color: #135a70;
}

.univ-btn-outlineprevnext {
  background: var(--brand-color);
  color: #fff;
  font-size: 14px;
  border: 0;
  padding: 10px 10px;
  border-radius: 8px;
  outline: none;
  transition: all ease 0.6s;
  display: inline-block;
  border: 1px solid var(--brand-color);
  text-decoration: none;
}

.univ-btn-outlineprevnext {
  background: transparent;
  border: 1px solid var(--brand-color);
  color: var(--brand-color);
}

.univ-btn-outlineprevnext:hover {
  transition: all ease 0.6s;
  box-shadow: 0 4px 20px rgb(0 0 0 / 43%);
  background: var(--brand-color);
  color: #fff;
}

.univ-btn-outlineprevnext[disabled] {
  cursor: not-allowed !important;
  pointer-events: none !important;
}





.w-50 {
  width: 50%;
}

.w-65 {
  width: 65%;
}




.card {
  border: 1px solid #dbdee5 !important;
  border-radius: 4px !important;
}





.modal-dialog.modal-50w {
  max-width: 50%;
}


img.cate-img {
  height: 250px;
  width : 90% !important
}

img.sub-cate-img {
  height: 70px;
}


.nav-tabs .nav-link.active {
  background: transparent !important;
}


div#nav-tab {
  border: none;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #1c6176 !important;
  border-bottom: 3px solid #1c6176 !important;
  border-color: transparent;
}

.nav-tabs .nav-link {
  color: #4e4c4c;
  border: none !important;
  font-weight: 600;
}




.shadow-sm {
  box-shadow: rgba(31, 31, 31, 0.1) 0px 3px 10px !important;
}





.card-hover {

  transition: all 0.3s cubic-bezier(0, 0, 0.5, 1) 0s;
}

.card-hover:hover {
  box-shadow: 0 0 40px -8px rgba(0, 0, 0, 0.16),
    0 0 24px -16px rgba(0, 0, 0, 0.16);
  transform: scale3d(1.03, 1.03, 1.08) translate3d(0.1rem, -0.25rem, 20rem);

  transition: all 800ms cubic-bezier(0.19, 1, 0.22, 1);
}



.card-toolbar.quiz-pagi .pagination {
  justify-content: start !important;
}



img.subcate-img.result-img {
  width: 50%;
}









@media (max-width: 991.98px) {
  .mob-w-100 {
    width: 100% !important;
  }

  .mob-ww-100 {
    width: 100% !important;
  }


  img.subcate-img {
    width: 25%;
    padding-top: 10px;
  }

  img.subcate-img.result-img {
    width: 30% !important;
    padding-top: 20px;
  }

  img.subcate-img.img-mob {
    width: 20%;
  }

  .nav-link.underline-mob {
    text-decoration: underline;
  }


}

