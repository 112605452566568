/* sidebar.css */

:root{
  --brand-color:#1c6176;
  --text-color: #303030
}
body{
  font-family: 'Poppins', sans-serif;
  color: var(--text-color);
  background: #f6f9ff; 
  background: -webkit-linear-gradient(to right, #ffffff, #dde1e9);
  background: linear-gradient(to right, #ffffff, #dde1e9);

}


.sidebar {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 300px; 
  background-color: var(--brand-color);
  transition: all 0.2s;
  border-radius: 18px; 
}
.addnewBtn{
  cursor: pointer;
  color: #fff;
  border-bottom: 1px solid #4d8b9e;
  padding-bottom: 10px;
  font-size: 14px;
}
#side_nav {
  height: 95vh;
  min-width: 300px;
  max-width: 300px;
  transition: all 0.2s;
  border-radius: 18px; 
  overflow-x: hidden; 
}


.content-1 {
width: 80% !important; 
overflow-x: hidden;  
}



.content-2{
  width: 60% !important; 
  display: flex;
  flex-direction: column-reverse;
}


.content {
  min-height: 100vh;
  width: 100%;   
}

.sidebr.active {
  background-color: #4a97ae;
}

a.text-decoration-none.sidebar-active.active {
  width: 100%;
  display: flex;
}

.sidebar li:hover {
  background-color: #4D8B9E;
}

.title-topic {
  color: #B6B6B6;
  font-size: 14px;
}
.top-content p{
font-size: 14px;
}
.sidebr ,.sidebrcontent {
  background-color: transparent;
  font-size: 14px;
  align-items: center;
  margin: 0px;
  padding: 0px 24px 0px calc(20px);
  height: 40px;
  display: flex;
  color: white;
  cursor: pointer;
  color: white;
  
}


.sidebr:hover,.sidebrcontent:hover {
  background-color: #4D8B9E;
  color: white;
  font-weight: 500;
}
.logout-button {
 font-size: 14px;
  background-color: #4D8B9E; 
  color: white; 
  border: none; 
  border-radius: 6px !important; 
  cursor: pointer; 
}

.logout-button:hover {
  background-color: #4a97ae; 
}
.togglebtn{
  display: none;
}
@media (max-width: 767px) {

  
.content-1 {
  width: 100% !important;  
}
.content-2 {
  width: 100% !important;  
}
button.btn.open-btn {
  position: absolute;
  top: 0.5px !important;
  text-align: right;
  right: 37px !important;
  outline: none;
  border: none;
}
button.btn.close-btn {
  outline: none;
  border: none;
}
.content {
  padding-right: 0 !important;
  padding-left: 0 !important;
}
.w-100-mob {
  width: 100% !important;
}
.content-1 {
  width: 100% !important;  
}
.ms-4 {
margin-left: 0rem!important;
}

}
@media(max-width: 992px){
  #side_nav{
    left: -100%;
    position: fixed;
    z-index: 9;
    top: 0;
    bottom: 0;
    height: 100vh !important;
    border-radius: 0px;
    padding-top: 1.2rem;
    min-width: 100%;
    max-width: 100%;
  }
  #side_nav.active{
    left: 0;
  }
  .togglebtn{
    display: block;
  }
  .main-container{
    display: block !important;
  }
  .chatWrapper{
    display: block !important;
  }
  .top-content{
    min-height: 84vh;
  }
}

@media (max-width: 991.98px) {   

button.btn.close-btn {
    position: absolute;
    right: 0;
    font-size: 20px;
    top: 0;
}
button.btn.open-btn {
    position: absolute;
    top: 0px !important;
    text-align: right;
    right: 116px;
    outline: none;
    border: none;
}
button.btn.close-btn {
    outline: none;
    border: none;
}
.content-1 {
  width: 100% !important;  
}
.main-container .ms-4 {
margin-left: 0rem!important;
}

}

/* textbox css  */

button {
  border: none;
  cursor: pointer;
}

button:hover {
  opacity: 0.8;
}

.wrapper {
  max-width: 100%;
  position: relative;
}

.wrapper textarea {
  width: 100%;
  border: none;
  background: #fff; 
  border-radius: 10px;
  box-shadow: 0 0px 15px rgb(0 0 0 / 10%);
  outline: none;
  padding: 12px 15px;
  box-sizing: border-box;
  transition: 0.3s;
  font-size: 14px;
  padding-right: 4rem;
}

.wrapper button {
  position: absolute;
  width: 35px;
  height: 35px;
  top: 45%;
  right: 15px;
  background: transparent;
  transform: translate(0, -50%);
  padding: 0;
}
.wrapper button svg{
  width: 35px;
  height: 35px;
}
.chatWrapper {
    min-height: 100vh;
    display: flex;
    align-items: center;
}

/* chat card */

/* chat css  */

.chat-title-text
{ 
  /* text-shadow: 0 4px 4px rgb(0 0 0 / 25%); */
  font-weight: bold;
  margin-bottom: 1rem;
}
.chatlogo{
  padding: 0 15px 15px 0;
}
.chatlogo img{
  max-width: 150px;
}
.chat-title-valmiki
{
  color: var(--brand-color)
}

.chat-title-geneseaction
{
  color: #FFFFFF
}
.card-feature {
  padding: 1rem;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  cursor: pointer;
}

.card-feature:hover,.card-feature-message:hover {
  background: #fff;
  box-shadow: 0 0px 15px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  transition: all ease 0.6s;
}

.card-feature h3 , .card-feature-message h3{
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 0.5rem;
  color: var(--brand-color);
}

.card-feature p {
  font-size: 14px;
  color: #b6b6b6;
}

.feature-card-title h3 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 1rem;
}

@media (max-width: 992px) {
  br {
      display: none;
  }
  .col-2{
      width: auto;
  }
  .card-feature {
    padding: 2rem 1rem;
  }

  .card-feature h3 {
    font-size: 14px;
  }

  .card-feature p {
    font-size: 12px;
  }

  .feature-card-title h3 {
    font-size: 16px;
  }
}

@media (max-width: 768px) {
  br {
      display: none;
  }
  .col-2{
      width: auto;
  }
  .card-feature {
    padding: 1.5rem 0.5rem;
  }

  .card-feature h3 {
    font-size: 12px;
  }

  .card-feature p {
    font-size: 10px;
  }

  .feature-card-title h3 {
    font-size: 14px;
  }
}


.card-feature-message {
  width: 100%;
  padding: 1rem;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  cursor: pointer;
}

.chaticon-head {
  background-color: var(--brand-color);
  color: #f6f9ff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 35px;
  height: 35px;
}
.top-content{
  display: flex;
  flex-direction: column;
  font-size: 14px;
  max-height: 85vh;
  overflow: auto;
}
.message.user-command{
  margin-right: auto;
  max-width: 90%;
  padding: 6px 10px;
  border-radius: 8px;
  background: #b9d5dd;
  margin-bottom: 1.3rem;
  color: #000;
  margin-top: -10px;
  display: inline-block;
}
.message.valmiki-bot{
  max-width: 90%;
  margin-right: auto;
  padding: 6px 10px;
  border-radius: 8px;
  background: #fff;
  color: #777;
  margin-bottom: 1.3rem;
  margin-top: -10px;
  display: inline-block;
}
.message.loading{
  margin: auto;
  border: 1px solid #999;
  padding: 6px 15px;
  border-radius: 8px;
  color: #999;
}
.message.file-type ul{
  list-style: none;
  margin: 0;
  padding: 0;
  border-radius: 8px;
  flex-wrap: wrap;
}
.message.file-type ul li{
  margin: 0 10px 10px 10px;
  border: 1px solid #ddd;
  width: 190px;
}
.message.file-type ul li a{
  cursor: pointer;
  transition: all ease 0.6s;
}
ul li .selected{
  border: 1px solid var(--brand-color);
  display: block;
  transition: all ease 0.6s;
}
.message.file-type ul li:last-child{
  border: 0;
}
.message.file-type ul li img{
  width: 100%;
  height: 250px;
  object-fit: contain;
  background: #fff;
  object-position: top;
  flex-wrap: wrap;
}
.addDocIcon{
  cursor: pointer;
}




/* loading animation */
/* KEYFRAMES */



@keyframes pulse {
  from {
    opacity: 1;
    transform: scale(1);
  }
  to {
    opacity: .25;
    transform: scale(.75);
  }
}


.spinner-box {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  color: var(--brand-color);
  font-size: 16px;
}


/* PULSE BUBBLES */

.pulse-container {
  width: 65px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pulse-bubble {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: var(--brand-color);
}

.pulse-bubble-1 {
    animation: pulse .4s ease 0s infinite alternate;
}
.pulse-bubble-2 {
    animation: pulse .4s ease .2s infinite alternate;
}
.pulse-bubble-3 {
    animation: pulse .4s ease .4s infinite alternate;
}
.chatBlock h6{
  font-size: 14px;
  margin: 0;
  color: #777;
  font-style: italic;
  margin-left: 1rem;
  margin-top: -10px;
}



.button-container {
  display: flex;
  justify-content: flex-end;
}
.btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: var(--brand-color);
  --bs-btn-border-color: var(--brand-color);
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: var(--brand-color);
  --bs-btn-hover-border-color: #var(--brand-color);
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: var(--brand-color);
  --bs-btn-active-border-color: var(--brand-color);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: var(--brand-color);
  --bs-btn-disabled-border-color: var(--brand-color);
}

.react-tooltip {
  max-width: 300px; 
  padding-right: 30px;
  border-radius: 4px;
  font-size: 14px;
  position: absolute;
 
}



.my-swal-icon {
  font-size: 10px;
}

.my-swal-icon {
  color: var(--brand-color)!important;
}

div.swal2-icon.swal2-info {
  border-color: var(--brand-color)!important;
  color: var(--brand-color) !important;
}

.markdownclass h2 {

  font-size: 19px;
 
}

.text-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
  font-size: 14px !important;
}

.markdownclass h1 {

  font-size: 21px;
 
}

.swal2-styled.swal2-confirm {
  box-shadow: none !important;
}